export default [
   
    {
      key: 'fromDate',
      label: 'field.fromDate',
      type: 'date',
      searchField: 'createdAt',
      operator: 'd>=',
    },
    {
      key: 'toDate',
      label: 'field.toDate',
      type: 'date',
      searchField: 'createdAt',
      operator: 'd<=',
    },
    {
      key: 'period',
      label: 'field.period',
      type: 'radio',
      options: [
        { text: 'field.today', value: 'today' },
        { text: 'field.yesterday', value: 'yesterday' },
        { text: 'field.thisWeek', value: 'thisWeek' },
        { text: 'field.lastWeek', value: 'lastWeek' },
        { text: 'field.thisMonth', value: 'thisMonth' },
        { text: 'field.lastMonth', value: 'lastMonth' },
      ],
      md: 12,
      lg: 6,
      skip: true,
    },
  ];
  