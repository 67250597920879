export default [
    {
      key: 'increasement',
      sortable: false,
      sortField: '',
      label: '#',
      thClass: 'w-50px',
    },
    {
      key: 'code',
      label: 'field.code',
      thClass: 'text-left',
      tdClass: 'text-left',
    },
    {
      key: 'requestedDate',
      label: 'field.requestedDate',
      thClass: 'text-left',
      tdClass: 'text-left',
    },
    {
      key: 'expectedDate',
      sortable: true,
      label: 'field.expectedDate',
      thClass: 'text-left',
      tdClass: 'text-left',
    },
    {
        key: 'grandTotal',
        sortable: true,
        label: 'field.grandTotal',
        thClass: 'text-left',
        tdClass: 'text-left',
    },
    {
      key: 'remark',
      label: 'field.remark',
      thClass: 'text-left',
      tdClass: 'text-left',
    },
    {
      key: 'createdAt',
      sortable: true,
      sortField: 'createdAt',
      label: 'field.createDate',
      thClass: 'text-left',
      tdClass: 'text-left',
    },
    {
      key: 'createdBy',
      label: 'field.createdBy',
      thClass: 'text-left',
      tdClass: 'text-left',
    },
    {
        key: 'actions',
        label: 'general.action',
    },
  ];
  